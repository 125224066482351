<template>
  <b-row>
    <b-col md="12">
      <b-card
        title="Devis soumis"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-card
                title="Devis soumis"
                no-body
              >
                Devis soumis
              </b-card>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1 ml-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between flex-wrap">
            <!-- sorting  -->
            <b-form-group
              label="Trier"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class="mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filtrer"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="devis"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <span @click="$router.push({ name: 'espace-societe.detail-devis', params: {code:data.item.code}})">
              <feather-icon icon="EyeIcon" />
              <span class="align-middle text-primary ml-50" />
            </span>
            <span
              v-b-modal.chatapp
              @click.prevent="getCommentByDevis(data.item.id)"
            >
              <feather-icon icon="MessageCircleIcon" />
              <span class="align-middle text-primary ml-50" />
            </span>

            <span @click="$router.push({ name: 'espace-societe.modifier-devis', params: {code: data.item.code}})">
              <feather-icon
                class="text-primary"
                icon="EditIcon"
              />
              <span class="align-middle text-primary ml-50" />
            </span>
            <span
              v-b-modal.suppression
              @click.prevent="getDevisCode(data.item.code, 'Suspendu')"
            >
              <feather-icon
                class="text-danger"
                icon="MinusCircleIcon"
              />
              <span class="align-middle text-danger ml-50" />
            </span>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="devis.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
    <b-modal
      id="chatapp"
      ref="my-modal"
      ok-title="Fermer"
      ok-only
      title="Messagerie"
      button-size="sm"
      ok-variant="primary"
      no-close-on-backdrop
      @ok="handleOk"
    >
      <div class="wrapper">
        <section class="chat-area">
          <header>
            <a
              href="#"
              class="back-icon"
            ><i class="fas fa-arrow-left" /></a>
            <img
              :src="require('@/assets/images/avatars/13-small.png')"
              alt=""
            >
            <div class="details">
              <span>Test Dev</span>
              <p>Active now</p>
            </div>
          </header>
          <div
            v-chat-scroll
            class="chat-box"
          >
            <div
              v-for="(commentaire, index) in commentaires"
              id="messages"
              :key="index"
            >
              <div
                class="chat"
                :class="commentaire.sender_id === user.id ? 'outgoing' :'incoming'"
              >
                <img
                  v-if="commentaire.sender_id !== user.id"
                  :src="require('@/assets/images/avatars/13-small.png')"
                  alt=""
                >
                <div class="details">
                  <p v-text="commentaire.message" />
                </div>
              </div>
            </div>

          </div>
          <b-form
            class="typing-area"
            @submit.prevent="saveCommentaire"
          >
            <input
              type="text"
              class="incoming_id"
              name="incoming_id"
              value="907234794"
              hidden=""
            >
            <input
              v-model="form.message"
              type="text"
              name="message"
              class="input-field"
              placeholder="Entrer votre message ..."
              autocomplete="off"
            >

            <b-button
              type="submit"
              variant="success"
            >
              <feather-icon icon="SendIcon" />
            </b-button>
          </b-form>
        </section>
      </div>
    </b-modal>
    <!-- form modal -->
    <b-modal
      id="suppression"
      ref="my-modal"
      cancel-title="Non"
      ok-title="Oui"
      modal-class="modal-danger"
      ok-variant="danger"
      button-size="sm"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="handleOk"
    >
      <form @submit.prevent="suspendreDevis">
        <h6>Etes-vous sûr de vouloir susprendre ce devis?</h6>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { ref, onMounted, reactive } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BFormGroup,
  BForm,
  BFormSelect,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'

// eslint-disable-next-line import/no-cycle
import useSocieteTransporteurs from '@/services/societe/societeTransporteurService'
import { $themeConfig } from '@themeConfig'
// eslint-disable-next-line import/no-extraneous-dependencies
import Pusher from 'pusher-js'
// eslint-disable-next-line import/no-cycle
import useAuthUser from '@/services/authentification/userService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BForm,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      getAllDevisBySocieteAndState, devis, SuspendDevis, loader, commentaires, getAllCommentByDevis, saveComment,
    } = useSocieteTransporteurs()
    const {
      getAuthUser, user,
    } = useAuthUser()
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const infoModal = {
      id: 'info-modal',
      title: '',
      content: '',
    }
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'code', label: 'CODE DU DEVIS', sortable: true },
      { key: 'offre.code', label: 'CODE OFFRE', sortable: true },

      { key: 'etat', label: 'ETAT', sortable: true },
      { key: 'ville_depart', label: 'DÉPART', sortable: true },
      { key: 'date_prev_depart', label: 'DTE DÉPART', sortable: true },
      { key: 'ville_destination', label: 'DESTINATION', sortable: true },
      { key: 'date_prev_livraison', label: 'DTE ARRIVEE', sortable: true },
      { key: 'created_at', label: 'DTE SOUMISSION', sortable: true },
      { key: 'actions' },
    ]
    const stateDevis = reactive({ etat: '' })
    const etat = ref('')

    /* eslint-disable global-require */
    const items = devis.value
    const devisCode = ref('')

    const getDevisCode = (code, stateValidity) => {
      devisCode.value = code
      stateDevis.etat = stateValidity
    }
    const form = reactive({
      devis_id: null,
      message: '',
    })
    const suspendreDevis = async (code, data) => {
      await SuspendDevis(code, data)
    }

    onMounted(async () => {
      await getAuthUser()

      await getAllDevisBySocieteAndState()
      // Set the initial number of items
      totalRows.value = items.length
    })
    const { PUSHER_APP_CLUSTER, PUSHER_APP_KEY } = $themeConfig.app
    const pusher = new Pusher(PUSHER_APP_KEY, {
      cluster: PUSHER_APP_CLUSTER,
    })

    const channel = pusher.subscribe('commentaire-devis-channel')

    channel.bind('commentaire-devis-event', async () => {
      if (form.devis_id !== null) {
        await getAllCommentByDevis(form.devis_id)
      }
    })
    const getCommentByDevis = async id => {
      form.devis_id = id
      await getAllCommentByDevis(id)
    }
    const saveCommentaire = async () => {
      await saveComment({ ...form })
      form.message = null
    }

    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }

    const formatter = value => value.toUpperCase()
    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      infoModal,
      fields,
      items,
      onFiltered,
      formatter,
      loader,
      form,
      devis,
      user,
      devisCode,
      getDevisCode,
      suspendreDevis,
      stateDevis,
      etat,
      getCommentByDevis,
      commentaires,
      saveCommentaire,

    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    handleSubmitModal() {
      // Exit when the form isn't valid
      this.suspendreDevis(this.devisCode, this.stateDevis)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
@import '../../../../../../assets/css/chat.css';
</style>
